import React from "react"
import { Link } from "gatsby"
import "../styles/reset.scss"
import "../styles/_typography.scss"
import "../styles/_layout.scss"

const Layout = ({ location, title, description, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <div>
        <h1
          style={{
            marginBottom: `16px`,
          }}
        >
          <Link
            to={`/`}
          >
            {title}
          </Link>
        </h1>
        <h2 className="subheading-home">{description}</h2>
        <div className="bio-text">by <a href="https://www.elizabethylin.com">elizabeth lin</a></div>
      </div>
    )
  } else {
    header = (
      <h3 className="logo">
        <Link
          style={{
            boxShadow: `none`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div className="wrapper">
      <header>{header}</header>
      <main>{children}</main>
      <footer>
      <form
        className="newsletter"
        action="https://buttondown.email/api/emails/embed-subscribe/elizabethylin"
        method="post"
        target="popupwindow"
        onsubmit="window.open('https://buttondown.email/elizabethylin', 'popupwindow')"
      >
        <label for="bd-email">💌</label>
        <input type="email" name="email" id="bd-email" />
        <input type="hidden" name="tag" value="blog" />
        <input type="submit" value="Subscribe" />
      </form>
      <script async src="https://eocampaign1.com/form/0495aab8-d02e-11ee-aa3b-6f1a1d7b12f9.js" data-form="0495aab8-d02e-11ee-aa3b-6f1a1d7b12f9"></script>
      </footer>
    </div>
  )
}

export default Layout
